<script>
import { Input, Button } from 'ant-design-vue'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getAction } from '@/command/netTool'
import { setCookie } from '@/utils/cookie'

export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
      phoneNumber: '',
      code: '',
      loginWay: false,
      loginWayCode: true,
      loginWayFindPass: true,
      getCodeMsg: '获取验证码',
      getDisabled: false,
      countdown: 60,
      timeOut: '',
      sysData: ['appCode', 'userInfos', 'tenantArr'],
    }
  },
  created() {
    sessionStorage.setItem('loginDYZ', 'true')
  },
  mounted() {
    this.sysData.forEach((e) => {
      localStorage.removeItem(e)
    })
  },
  methods: {
    ...mapActions(['Login', 'Logout', 'GetCode', 'LoginByCode', 'GetCodeFind', 'UpdatePwd']),
    renderFormItem(item) {
      return (
        <div class={['login-form-item', item.key]}>
          <Input
            v-model={this[item.key]}
            type={item.key}
            placeholder={item.placeholder}
            disabled={item.disabled}
            onPressEnter={() => item.onChange()}
          />
        </div>
      )
    },
    loginSuccess(userData) {
      let { user_info, access_token, token_type } = userData
      localStorage.setItem('userInfos', JSON.stringify(user_info))
      setCookie('access_token', `${access_token}`, 5)
      getAction('/farmTenant/myList').then((res) => {
        if (res.code == 200) {
          let arr = res.data.filter((s) => s)
          if (arr.length > 0) {
            localStorage.setItem('tenantArr', JSON.stringify(arr))
            localStorage.setItem('appCode', arr[0]['tenantId'])
            localStorage.setItem('logo', arr[0].sysIcon || '')
            this.setJump()
          } else {
            this.$message.error('没有租户不可登入！')
            return
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    setJump() {
      if (process.env.NODE_ENV === 'development') {
        this.$router.push('/user/navDYZ')
      } else {
        getAction('/admin/menu/userTree?sysCode=sznc', {}, '/bpi').then((e) => {
          if (e.data) {
            this.$router.push({
              path: `/user/navDYZ`,
              // path: `/${e['data'][0]['path'].replace('/', '')}`,
            })
            setTimeout(() => {
              this.$notification.success({
                message: '欢迎',
                description: `${timeFix()}，欢迎回来`,
              })
            }, 1000)
          } else {
            this.$message.warning('您暂无权限，不可进入！')
          }
        })
      }
    },
    onLogin() {
      if (this.username === '' || this.password === '') {
        this.$message.error('请输入账号/密码')
        return
      }
      this.Login({
        username: this.username,
        password: this.password,
      })
        .then((result) => this.loginSuccess(result))
        .catch((err) => {
          getAction('/admin/account/lock/queryLockInfo', { username: this.username }, '/bpi').then((res) => {
            if (res.code === 200) {
              let { data } = res
              if (data.lockStatus == '0') {
                this.$message.error(`账号密码错误，还有${data.errorTimes}次账号将锁定！`)
              } else if (data.lockStatus == '1') {
                this.$message.error(`账号已锁定，请${data.lockTimes}分钟后充重试！`)
              } else {
                this.$message.error(err.msg)
              }
            } else {
              this.$message.error('登录失败，请重新登入！')
            }
          })
        })
        .finally(() => {
          // state.loginBtn = false
        })
    },
    // 验证码登录按钮
    onLoginByCode() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      this.LoginByCode({
        phoneNumber: this.phoneNumber,
        code: this.code,
      }).then((e) => {
        if (e.access_token) {
          this.loginSuccess(e)
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    renderFormButton() {
      return (
        <div class="login-form-button" onClick={this.onLogin}>
          登录
        </div>
      )
    },
    renderFormButton2() {
      return (
        <div class="login-form-button" onClick={this.onLoginByCode}>
          登录
        </div>
      )
    },
    renderFormButton3() {
      return (
        <div class="login-form-button" onClick={this.onNext}>
          确认
        </div>
      )
    },
    // 找回手机确认
    onNext() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      this.UpdatePwd({
        code: this.code,
        password: this.password,
        phone: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.resetValue()
          this.$message.success('修改密码成功')
          this.loginWayFindPass = !this.loginWayFindPass
          this.loginWay = !this.loginWay
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    renderForm() {
      const form = [
        {
          name: '账号/用户名',
          key: 'username',
          placeholder: '请输入登录账号',
        },
        {
          name: '登录密码',
          key: 'password',
          placeholder: '请输入登录密码',
          onChange: () => {
            this.onLogin()
          },
        },
      ]
      const form2 = [
        {
          name: '手机号',
          key: 'phoneNumber',
          placeholder: '请输入手机号',
        },
        {
          name: '验证码',
          key: 'code',
          type: 'number',
          placeholder: '请输入验证码',
          onChange: () => {
            this.onLoginByCode()
          },
        },
      ]
      const form3 = [
        {
          name: '手机号',
          key: 'phoneNumber',
          placeholder: '请输入手机号',
        },
        {
          name: '输入新密码',
          key: 'password',
          placeholder: '请输入新密码',
        },
        {
          name: '验证码',
          key: 'code',
          placeholder: '请输入验证码',
          onChange: () => {
            this.onNext()
          },
        },
      ]

      return (
        <div class="login-form">
          <div class="login-form-title">账号登录</div>
          <div class="login-form-view" v-show={!this.loginWay}>
            {form.map((e) => this.renderFormItem(e))}
            {this.renderChangeLogin()}
            {this.renderFormButton()}
          </div>
          <div class="login-form-view" v-show={!this.loginWayCode}>
            {form2.map((e) => this.renderFormItem(e))}
            {this.renderLoginButton()}
            {this.renderChangeLogin2()}
            {this.renderFormButton2()}
          </div>
          <div class="login-form-view2" v-show={!this.loginWayFindPass}>
            {form3.map((e) => this.renderFormItem(e))}
            {this.renderLoginButton2()}
            {this.renderChangeLogin3()}
            {this.renderFormButton3()}
          </div>
        </div>
      )
    },
    renderLoginButton() {
      let text = this.getCodeMsg
      return (
        <div class="login-code-button">
          <Button type="ghost" onClick={this.getCode} class="login-code-button2" disabled={this.getDisabled}>
            {{ text }}
          </Button>
        </div>
      )
    },
    renderLoginButton2() {
      let text = this.getCodeMsg
      return (
        <div class="login-code-button">
          <Button type="ghost" onClick={this.getCodeFind} class="login-code-button2" disabled={this.getDisabled}>
            {{ text }}
          </Button>
        </div>
      )
    },
    // 获取找回密码验证码
    getCodeFind() {
      this.GetCodeFind({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    // 获取验证码
    getCode() {
      this.GetCode({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    setCountdown: function () {
      if (this.countdown === 0) {
        this.getCodeNow()
      } else {
        this.getCodeMsg = this.countdown + '重新获取'
        this.getDisabled = true
        this.countdown--
      }
    },

    getCodeNow() {
      this.getCodeMsg = '获取验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
    },
    renderChangeLogin() {
      return (
        <div class="login-change">
          <a href="#" onClick={this.loginCode}>
            验证码登录
          </a>
          <a href="#" onClick={this.loginFindCode}>
            找回密码
          </a>
        </div>
      )
    },
    // 找回密码双返回按钮
    renderChangeLogin3() {
      return (
        <div class="login-change">
          <a href="#" onClick={this.loginBackCount}>
            账号密码登录
          </a>
          <a href="#" onClick={this.loginBackCode}>
            验证码登录
          </a>
        </div>
      )
    },
    // 重置
    resetValue() {
      this.getCodeMsg = '获取验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
      this.timeOut = ''
      this.username = ''
      this.password = ''
      this.phoneNumber = ''
      this.code = ''
    },
    loginBackCode() {
      this.resetValue()
      this.loginWayFindPass = !this.loginWayFindPass
      this.loginWayCode = !this.loginWayCode
    },
    loginBackCount() {
      this.resetValue()
      this.loginWayFindPass = !this.loginWayFindPass
      this.loginWay = !this.loginWay
    },
    // 找回密码
    loginFindCode() {
      this.resetValue()
      this.loginWay = !this.loginWay
      this.loginWayFindPass = !this.loginWayFindPass
    },
    renderChangeLogin2() {
      return (
        <div class="login-change">
          <a href="#" onClick={this.loginCode}>
            账号密码登录
          </a>
        </div>
      )
    },
    // 验证码切换登录按钮
    loginCode() {
      // this.getCodeMsg = '获取验证码'
      // this.getDisabled = false
      // this.countdown = 60
      // clearInterval(this.timeOut)
      this.resetValue()
      this.loginWay = !this.loginWay
      this.loginWayCode = !this.loginWayCode
    },
    renderBody() {
      return <div class="login-body">{this.renderForm()}</div>
    },
    renderRecord() {
      return (
        <a class="index-record" target="_blank" href="http://beian.miit.gov.cn">
          备案号：浙ICP备18036304号-6
        </a>
      )
    },
  },
  render() {
    return (
      <div class="login-main">
        {this.renderBody()}
        {this.renderRecord()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.login-main {
  display: flex;
  background-image: url('../../assets/View/login-dyz.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.index-record {
  position: absolute;
  bottom: 50px;
  left: 45.5%;
  color: #999;
}
.login-body {
  // width: 813px;
  // height: 440px;
  // display: flex;
  // // background-image: url('../../assets/View/login-body.png');
  // // background-size: 100% 100%;
  // position: relative;
}
.login-form {
  position: absolute;
  right: 300px;
  top: 50%;
  bottom: 0;
  background-color: white;
  border-radius: 30px;
  width: 480px;
  min-height: 492px;
  height: fit-content;
  padding: 70px 80px 85px;
  transform: translateY(-50%);
  /deep/.ant-input-affix-wrapper {
    border: none;
    background: #f6f6f6;
    outline: none;
    width: 95%;
    box-shadow: none;
  }

  .login-form-title {
    text-align: center;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    font-size: 24px;
    color: #575454;
    margin-bottom: 37px;
  }
}
.login-form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 323px;
  height: 55px;
  background: linear-gradient(270deg, #3b635a 0%, #6c9b9d 100%);
  border-radius: 8px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 22px;
  color: #ffffff;
  margin-top: 42px;
  cursor: pointer;
}
.login-form-item {
  width: 323px;
  height: 55px;
  background: #f2f4f9;
  border-radius: 8px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  input {
    border: none;
    outline: none;
    background: transparent;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    &::placeholder {
      color: #a9a9a9;
    }
  }
  &::before {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }
}
.phoneNumber{
  input{
    width: calc(100% - 120px);
  }
  &::before {
    background-image: url('../../assets/View/icon-phone.png');
  }
}
.username {
  &::before {
    background-image: url('../../assets/View/icon-user.png');
  }
}
.code{
  &::before {
    background-image: url('../../assets/View/icon-code.png');
  }
}
.password {
  &::before {
    background-image: url('../../assets/View/icon-password.png');
  }
}
.ant-input:focus {
  box-shadow: none;
}
.login-form-view {
  position: relative;
  margin-top: 40px;
}
.login-form-view2 {
  position: relative;
  margin-top: 20px;
}
.login-change {
  overflow: hidden;
  :nth-of-type(2) {
    float: right;
  }
}
a {
  float: left;
  display: block;
  margin-top: 15px;
  text-decoration: none;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #25615e;
}
.login-code-button {
  //border: 1px solid #dcdee2;
  position: absolute;
  height: 55px;
  top: 0;
  right: -2px;

  cursor: pointer;
  text-align: center;
}
.login-code-button2 {
  width: 110px;
  height: 55px;
  background: linear-gradient(270deg, #3b635a 0%, #6c9b9d 100%) !important;
  border-radius: 8px;
  line-height: 28px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-align: center
}
.ant-btn-ghost:hover, .ant-btn-ghost:focus{
  border: none;
}
</style>
